<template>
  <v-container class="pt-1 pb-0" fluid>
    <base-material-card inline icon="mdi-clipboard-text" :title="$t('Meniuri')">
      <table-management ref="table" table="menu" edit-type="custom" @addData="addItem" @editData="editItem" />
      <add-menu ref="addData" @reload="reloadData" />
    </base-material-card>
  </v-container>
</template>
<script>
import TableManagement from "../../components/containers/TableManagement"
import AddMenu from "./AddMenu"
export default {
  components: { AddMenu, TableManagement },
  data: () => ({}),
  computed: {},

  methods: {
    editItem(item) {
      this.$refs.addData.editData(item)
    },
    addItem() {
      this.$refs.addData.addData()
    },
    reloadData() {
      this.$log("reloaddata")
      this.$refs.table.getRecords()
    }
  }
}
</script>
