<template>
  <v-dialog v-model="show" persistent scrollable max-width="1200px">
    <v-card outlined>
      <!-- <v-system-bar color="grey lighten-2" height="32">
        <v-spacer />
        <v-btn icon small @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-system-bar> -->
      <v-card-title>
        {{ $t("Meniu") }}
        <v-spacer />

        <!-- <v-icon aria-label="Close" @click="show = false">
          mdi-close
        </v-icon> -->
        <v-icon color="red" class="mt-n2 mr-n2" large @click="show = false">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text>
        <v-form ref="dataForm">
          <v-row dense>
            <v-col cols="6">
              <v-text-field v-model="title" autofocus :label="$t('Nume')" :rules="inputRequired" />
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="menu_type" :label="$t('Tip meniu')" :rules="inputRequired" />
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="appTypes"
                dense
                :label="$t('Tip aplicație')"
                item-text="name"
                item-value="value"
                :rules="inputRequired"
                :items="appTypesValues"
              />
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="userTypes"
                dense
                multiple
                :label="$t('Tip utilizator')"
                item-text="name"
                item-value="value"
                :rules="inputRequired"
                :items="userTypesValues"
              />
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="companies"
                dense
                multiple
                :disabled="appType != 'system'"
                :label="$t('Companie')"
                item-text="name"
                item-value="_id"
                :items="companiesValues"
              />
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="users"
                dense
                multiple
                :label="$t('Utilizatori')"
                item-text="Utilizator"
                item-value="_id"
                :items="usersValues"
              />
            </v-col>
          </v-row>
          <v-row dense style="height: 100%;">
            <v-col cols="12">
              <draggable
                class="list-group"
                style="overflow-y: auto;"
                :list="mainList"
                :clone="cloneComponent"
                :group="{ name: 'g1', pull: 'clone', put: false }"
              >
                <!-- <li style="cursor:move" class="list-group-item pt-2" v-for="el in mainList" :key="el.id">
                  <v-system-bar
                    ><v-icon>{{ el.icon }}</v-icon
                    >{{ el.type }}</v-system-bar
                  >
                </li> -->
                <v-chip
                  v-for="el in mainList"
                  :key="el.id"
                  style="cursor: move;"
                  label
                  class="list-group-item ml-2 mt-2"
                >
                  <v-icon>{{ el.icon }}</v-icon>
                  {{ el.type }}
                </v-chip>
              </draggable>
            </v-col>
            <v-col class="page-card-design" cols="9">
              <scream-drag
                :schema="list"
                :selected-id="selectedId"
                style="height: 100%;"
                @selectedIdChange="
                  selectedId = $event.id
                  selectedItem = $event
                "
              />
            </v-col>
            <v-col class="page-card" cols="3">
              <v-sheet
                v-show="selectedItem.type"
                class="pt-3"
                style="height: 100%; overflow-y: auto; overflow-x: hidden;"
              >
                <v-text-field v-model="selectedItem.key" dense :label="$t('Titlu')" />
                <v-autocomplete
                  v-model="selectedItem.component"
                  clearable
                  dense
                  :label="$t('Componentă')"
                  :items="components"
                  item-text="name"
                  item-value="value"
                />
                <v-autocomplete
                  v-if="selectedItem.component == 'Databases'"
                  v-model="selectedItem.collection"
                  clearable
                  dense
                  :label="$t('Colecție')"
                  :items="tables"
                  item-text="title"
                  item-value="collection"
                  @change="getViews"
                  @update:search-input="getViews(selectedItem.collection)"
                />
                <v-autocomplete
                  v-if="selectedItem.component == 'Databases'"
                  v-model="selectedItem.view"
                  clearable
                  dense
                  :label="$t('Vizualizator')"
                  :items="views"
                  item-text="name"
                  item-value="_id"
                />
                <v-autocomplete
                  v-if="selectedItem.component == 'Databases'"
                  v-model="selectedItem.table"
                  clearable
                  dense
                  :label="$t('Tabel')"
                  :items="viewTables"
                  item-text="name"
                  item-value="_id"
                />
                <v-text-field
                  v-if="selectedItem.component == 'Databases'"
                  v-model="selectedItem.where"
                  dense
                  :label="$t('Filtru')"
                />

                <v-text-field v-model="selectedItem.path" dense :label="$t('Cale')" />
                <v-text-field v-model="selectedItem.ico" dense :label="$t('Pictogramă')" />
                <v-checkbox v-model="selectedItem.enabled" dense :label="$t('Activ')" />
                <v-spacer />
              </v-sheet>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="error" @click="show = false">
          {{ $t("Anulează") }}
        </v-btn>
        <v-btn
          v-show="recordId"
          text
          color="info"
          @click="
            recordId = 0
            saveData()
          "
        >
          {{ $t("Adaugă") }}
        </v-btn>
        <v-btn color="success" @click="saveData">
          {{ $t("Salvează") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "../../plugins/axios"
import ScreamDrag from "../../components/containers/ScreamDrag"
import { EventBus } from "../../EventBus"
import draggable from "vuedraggable"
let idGlobal = 50
export default {
  components: {
    draggable,
    ScreamDrag
  },
  data: () => ({
    companiesValues: [],
    usersValues: [],
    appTypesValues: [],
    userTypesValues: [],
    companies: [],
    users: [],
    appTypes: [],
    userTypes: [],
    tables: [],
    viewTables: [],
    views: [],
    // appTypes: [],
    recordId: "",
    record: {},
    etag: "",
    show: false,
    title: "",
    menu_type: "",
    list: [],
    listJson: {},
    selectedId: 0,
    selectedItem: {},
    components: [],
    mainList: [
      {
        id: 1,
        key: "",
        component: "",
        collection: "",
        where: "",
        view: "",
        path: "",
        enabled: true,
        ico: "",
        type: "menu",
        icon: "mdi-code-brackets",
        schema: []
      }
    ]
  }),
  computed: {
    inputRequired() {
      const rule = []
      rule.push(v => !!v || this.$t("required"))
      return rule
    },
    companyId() {
      return this.$store.getters.companyId
    },
    userappTypes() {
      return this.$store.getters.appTypes
    },
    appType() {
      return this.$store.getters.appType
    }
  },
  mounted() {
    let compurl = "app_company"
    let userurl = "app_user"
    if (this.appType !== "system") {
      compurl = 'app_company?where={"_id":"' + this.companyId + '"}'
      userurl = 'app_user?where={"company":"' + this.companyId + '"}'
      this.companies = [this.companyId]
    }

    axios.get('app_system_dictionary?where={"value":"app_type"}').then(response => {
      this.$log("app type ", response)
      // this.appTypesValues = response.data._items[0].content
      if (this.appType !== "system") {
        this.appTypesValues = response.data._items[0].content.filter(itm => this.userappTypes.includes(itm.value))
      } else {
        this.appTypesValues = response.data._items[0].content
      }
    })
    axios.get('app_system_dictionary?where={"value":"user_type"}').then(response => {
      if (this.appType !== "system") {
        this.userTypesValues = response.data._items[0].content.filter(itm => itm.value !== "superadmin")
      } else {
        this.userTypesValues = response.data._items[0].content
      }
    })
    axios.get(compurl).then(response => {
      this.companiesValues = response.data._items
    })
    axios.get(userurl).then(response => {
      this.usersValues = response.data._items
    })
    // axios.get('app_dictionary?where={"value":"appType"}').then(response => {
    //   this.$log("app_company reload", response)
    //   this.appTypes = response.data._items[0].content
    // })
    axios.get('app_system_dictionary?where={"value":"components"}').then(response => {
      this.$log("app_company reload dictionary ", response)
      this.components = response.data._items[0].content
    })

    this.getSchema()
  },
  methods: {
    getSchema() {
      // this.$log("getSchema")
      axios.get('app_schema?projection={"title":1,"collection":1}&max_results=1000').then(response => {
        this.$log("schema reload", response)
        this.tables = response.data._items
      })
    },
    getViews(col) {
      //axios.get('app_view?where={"collection":"' + col + '"}').then(response => {
      //this.$log("schema reload", response)
      //this.$log("appView reload", col)
      //this.views = this.$store.getters.appViewCollection(col)
      this.views = this.$store.getters.appView.filter(itm => itm.collection == col && itm.type == "form")
      this.viewTables = this.$store.getters.appView.filter(itm => itm.collection == col && itm.type == "table")
      //})
    },
    cloneComponent(prop) {
      const newprop = { ...prop }
      newprop.id = idGlobal++
      if (newprop.schema) newprop.schema = []
      return newprop
    },
    addData() {
      this.show = true
      this.recordId = 0
      this.title = ""
      this.menu_type = ""
      this.list = []
      this.selectedItem = {}
      this.$nextTick(() => this.$refs.dataForm.resetValidation())
    },
    editData(e) {
      this.show = true
      this.recordId = e._id
      this.etag = e._etag
      // this.$log("etag ", this.etag)
      axios.get("app_menu/" + this.recordId).then(response => {
        this.$log("schema", response)
        response.data._created = undefined
        response.data._updated = undefined
        response.data._links = undefined
        response.data._version = undefined
        response.data._latest_version = undefined
        this.record = response.data
        this.title = response.data.name
        this.menu_type = response.data.menu_type
        this.etag = response.data._etag
        this.list = []
        if (this.record.owner) {
          this.companies = this.record.owner.companies
          this.users = this.record.owner.users
          this.appTypes = this.record.owner.app_type
          this.userTypes = this.record.owner.user_type
        } else {
          this.companies = []
          this.users = []
          this.appTypes = []
          this.userTypes = []
        }
        this.generateList(this.list, response.data.content)
      })
    },
    generateList(list, rec) {
      for (const prop in rec) {
        const dic = { ...this.mainList[0] }
        Object.assign(dic, rec[prop])
        dic.id = idGlobal++
        dic.key = rec[prop].title
        dic.ico = rec[prop].icon
        dic.icon = "mdi-code-brackets"
        dic.schema = []
        if (rec[prop].items) {
          dic.schema = []
          this.generateList(dic.schema, rec[prop].items)
        }
        list.push(dic)
      }
    },
    getMenu(menu, items) {
      for (const itm in items) {
        const dic = { ...items[itm] }
        dic.title = items[itm].key
        dic.icon = items[itm].ico
        dic.key = undefined
        dic.name = undefined
        dic.ico = undefined
        dic.id = undefined
        dic.type = undefined
        if (Object.entries(items[itm].schema).length > 0) {
          dic.items = this.getMenu([], items[itm].schema)
        }
        dic.schema = undefined
        menu.push(dic)
      }
      return menu
    },
    saveData() {
      this.$log("thismenu2 ", this.getMenu([], this.list))

      if (this.$refs.dataForm.validate()) {
        this.$log("this.listJson ", this.listJson)
        this.$log(" this.appType ", this.appType)
        // this.$log("this.listJson ", JSON.stringify(this.listJson))
        if (this.recordId === 0) {
          axios
            .post("app_menu/", {
              name: this.title,
              menu_type: this.menu_type,
              content: this.getMenu([], this.list),
              owner: {
                user: this.users,
                company: this.companies,
                app_type: this.appTypes,
                user_type: this.userTypes
              }
            })
            .then(response => {
              this.$log("saveData", response)
              if (response.status === 201) {
                this.show = false
                this.$emit("reload")
              }
            })
            .catch(error => {
              this.$log("error ", error)
              // this.$log("error ", error._issues.content.name)
              EventBus.$emit("show-alert", { message: "error", timeOut: 7000 })
            })
        } else {
          // put
          this.record.name = this.title
          this.record.menu_type = this.menu_type
          this.record.content = this.getMenu([], this.list)
          this.record._etag = undefined
          this.record.owner = {
            user: this.users,
            company: this.companies,
            app_type: this.appTypes,
            user_type: this.userTypes
          }
          // this.$log("this.record ", this.record)
          axios
            .put("app_menu/" + this.recordId, this.record, {
              headers: {
                "If-Match": this.etag
              }
            })
            .then(response => {
              this.$log("saveData", response)
              if (response.status === 200) {
                this.show = false
                this.$emit("reload")
              }
            })
            .catch(error => {
              this.$log("error ", error)
              // this.$log("error ", error._issues.content.name)
              EventBus.$emit("show-alert", { message: "error", timeOut: 7000 })
            })
        }
      }
    }
  }
}
</script>
<style scoped>
.page-card {
  height: calc(100vh - 490px);
  overflow-y: auto;
  overflow-x: hidden;
}
.page-card-design {
  height: calc(100vh - 490px);
  overflow-y: auto;
  overflow-x: hidden;
}
ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}
</style>
